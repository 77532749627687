import React, { useEffect, useRef, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { LOGO } from "../../assets/images";
import SignatureCanvas from "react-signature-canvas";
import "./style.css";
import { useDispatch } from "react-redux";
import {
  AppDispatch,
  getInvoiceDataAction,
  fileUploadAction,
  submitAfterSignedAction,
} from "../../redux";
import { useParams } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const InvoiceHandyman = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { invoice_id } = useParams();

  const contentRef: any = useRef();
  const footerRef: any = useRef();
  const sigCanvas: any = useRef(null);

  const [canvasWidth, setCanvasWidth] = useState(500);
  const [percentage, setPercentage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState<any>();

  useEffect(() => {
    getInvoiceData();
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setCanvasWidth(window.innerWidth - 100);
      } else {
        setCanvasWidth(270);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInvoiceData = async () => {
    await dispatch(
      getInvoiceDataAction(invoice_id, (e) => {
        console.log("This is dataaaa", e);
        setInvoiceData(e);
      })
    );
  };

  const clearSignature = () => sigCanvas.current.clear();

  // const saveSignature = () => {
  //     const signatureDataURL = sigCanvas.current.toDataURL();
  //     console.log(signatureDataURL);
  //     alert("Signature saved! Check the console for the image URL.");
  // };

  const validateSignature = (): boolean => {
    if (sigCanvas.current.isEmpty()) {
      toast.error("Please create a digital signature before submitting.", {
        autoClose: 3000,
      });
      return false;
    }
    return true;
  };

  const handleDownloadPDF = async (): Promise<void> => {
    if (!validateSignature()) {
      return;
    }
    setIsLoading(true);
    const clearButton = document.querySelector(".no-print") as HTMLElement;
    if (clearButton) clearButton.style.display = "none";

    const A4_WIDTH = 210; // A4 width in mm
    // const A4_HEIGHT = 297; // A4 height in mm
    const SCALE = 1.5;

    try {
      const contentElement = contentRef.current;
      const footerElement = footerRef.current;

      if (!contentElement || !footerElement) {
        console.error("Content or footer elements are not found in the DOM");
        return;
      }

      // Generate content canvas
      const contentCanvas = await html2canvas(contentElement, {
        scale: SCALE,
        useCORS: true, // Ensure cross-origin images load
      });

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });
      // Calculate dimensions for centering
      const contentWidthMM = A4_WIDTH - 20; // Leave a margin
      const contentHeightMM =
        (contentCanvas.height * contentWidthMM) / contentCanvas.width;
      const xOffsetContent = (A4_WIDTH - contentWidthMM) / 2;
      pdf.addImage(
        contentCanvas.toDataURL("image/jpeg", 0.9), // Use high-quality compression
        "JPEG",
        xOffsetContent,
        10, // Top margin
        contentWidthMM,
        contentHeightMM
      );
      // Add new page for footer
      pdf.addPage();
      const footerCanvas = await html2canvas(footerElement, {
        scale: SCALE,
        useCORS: true,
      });
      const footerHeightMM =
        (footerCanvas.height * contentWidthMM) / footerCanvas.width;
      const xOffsetFooter = (A4_WIDTH - contentWidthMM) / 2;
      pdf.addImage(
        footerCanvas.toDataURL("image/jpeg", 0.9),
        "JPEG",
        xOffsetFooter,
        10,
        contentWidthMM,
        footerHeightMM
      );

      // Generate and upload PDF
      const pdfBlob = pdf.output("blob");
      const fileName = `invoice_${Date.now()}.pdf`;

      // Create a File object
      const file = new File([pdfBlob], fileName, { type: "application/pdf" });

      console.log("This is file", file)
      // Upload to S3
      await uploadToS3(file);
    } catch (error) {
      console.error("Error generating or uploading PDF:", error);
    } finally {
      // Restore the "Clear" button after PDF generation
      if (clearButton) clearButton.style.display = "";
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    }
  };

  // Function to upload to S3
  const uploadToS3 = async (file: File): Promise<void> => {
    try {
      const onError = (error: any) => {
        toast.error(error, { autoClose: 3000 });
      };
      const onSuccess = async (data: any, type: string) => {
        console.log("This is something new3", data.result.location);
        let obj = {
          invoice_id: invoiceData.id,
          proposal_pdf_link: data.result.location,
          franchise_id: invoiceData.franchise_id,
          ghl_opportunity_id: invoiceData.ghl_opportunity_id,
          client_id: invoiceData.client_id,
          trainer_id: invoiceData.trainer_id,
        };
        console.log("This is obj", obj);
        await dispatch(
          submitAfterSignedAction(obj, onSuccessFunc, onErrorFunc)
        );
      };

      const onSuccessFunc = () => {
        toast.success("Invoice successfully accepted", { autoClose: 3000 });
        setTimeout(() => {
          navigate("/thank-you-invoice");
        }, 100); // Redirect after the toast is displayed
      };

      const onErrorFunc = (error: any) => {
        toast.error(error, { autoClose: 3000 });
      };

      const formData = new FormData();

      // Update the formData object
      formData.append("file", file, invoiceData.id);
      const response: any = await dispatch(
        fileUploadAction(
          `proposals`,
          formData,
          file.type,
          (percent: any) => {
            setPercentage(percent);
          },
          onSuccess,
          onError
        )
      );

      // const result = await response.location;
      console.log("Upload result:", response);
    } catch (error) {
      console.error("Error uploading to S3:", error);
    }
  };

  const getSubTotal = () => {
    if(invoiceData) {
      let price = invoiceData?.InvoiceItems?.reduce((total: number, item: any) => total + parseFloat(item.price) * parseInt(item.quantity, 10), 0) || 0;
      return price.toFixed(2);
    }
  };

  const getDiscountvalue: any = () => {
    if(invoiceData) {
      const { discount } = invoiceData;
      if (discount?.value) {
        if (discount.type === 'Percentage') {
          const discountAmount = (getSubTotal() * parseFloat(discount.value)) / 100;
          return discountAmount.toFixed(2)
        } else if (discount.type === 'Fix Amount') {
          return (parseFloat(discount.value)).toFixed(2);
        }
      }
    }
  };

  const getFinalValue = () => {
    return (getSubTotal() - getDiscountvalue()).toFixed(2)
  };

  const invoiceContent = () => {
    console.log("InvoiceData", invoiceData);
    return (
      <>
        <div ref={contentRef}>
          <div className="proposal-header">
            <h3>Proposal/Estimate</h3>
          </div>
          <div className="logo-container">
            <img alt="logo" src={LOGO} className="logo" />
          </div>
          <div className="flex-row">
            <div>
              <p>
                <span className="bold-text">DATE:</span>{" "}
                {moment(invoiceData && invoiceData.proposal_sent_date).format(
                  "MM/DD/YYYY"
                )}
              </p>
              <p>
                <span className="bold-text">NAME:</span>{" "}
                {invoiceData && invoiceData.User && invoiceData.User.firstname}{" "}
                {invoiceData && invoiceData.User && invoiceData.User.lastname}
              </p>
              <p>
                <span className="bold-text">ADDRESS:</span> N/A
              </p>
            </div>
            <div>
              <p>
                <span className="bold-text">PHONE:</span>{" "}
                {(invoiceData && invoiceData.User && invoiceData.User.phone) ||
                  "N/A"}
              </p>
              <p>
                <span className="bold-text">HANDYMAN NAME:</span>{" "}
                {invoiceData &&
                  invoiceData.Trainer &&
                  invoiceData.Trainer.firstname}{" "}
                {invoiceData &&
                  invoiceData.Trainer &&
                  invoiceData.Trainer.lastname}
              </p>
              <p>
                <span className="bold-text">ZIP:</span> N/A
              </p>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Price</th>
                <th>Qty</th>
                <th>Subtotal</th>
              </tr>
            </thead>
            <tbody>
              {invoiceData &&
                invoiceData.InvoiceItems &&
                invoiceData.InvoiceItems.length &&
                invoiceData.InvoiceItems.map((e: any) => {
                  console.log("This is e", e);
                  return (
                    <tr key={e.id}>
                      <td>
                        <input
                          type="checkbox"
                          checked
                          readOnly
                          style={{ marginRight: "10px" }}
                        />
                        {e.name}
                      </td>
                      <td>${e.price}.00</td>
                      <td>{e.quantity}</td>
                      <td className="values-align-right">${e.price}.00</td>
                    </tr>
                  );
                })}
              <tr className="subtotal-row">
                <td></td>
                <td></td>
                <td>Subtotal:</td>
                <td className="values-align-right">${getSubTotal()}</td>
              </tr>
              <tr className="subtotal-row">
                <td></td>
                <td></td>
                <td>Discount:{invoiceData?.discount?.type === 'Percentage' ? `${invoiceData?.discount?.value}%` : null}</td>
                <td className="values-align-right">${getDiscountvalue()}</td>
              </tr>
              <tr className="subtotal-row">
                <td></td>
                <td></td>
                <td>Amount due:</td>
                <td className="values-align-right">${getFinalValue()}</td>
              </tr>
            </tbody>
          </table>
          <div className="signature-section">
            <p>
              <span className="bold-text">Manish Naruka</span>
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className="signature-box"
                style={{ width: canvasWidth, borderColor: "#cdcd00" }}
              >
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="black"
                  canvasProps={{
                    width: canvasWidth,
                    height: 100,
                    className: "signature-canvas",
                  }}
                />
              </div>
              <div>
                <Button
                  style={{ marginLeft: 5 }}
                  className="background-primary border-color-primary font-family-poppins text-light w-100 rounded-3 no-print"
                  onClick={() => clearSignature()}
                >
                  clear
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div ref={footerRef} className="footer">
          <ul>
            <li>
              <span className="bold-text">
                Payment is due upon job completion. At no time should any
                payments be made directly to individual Handyman.
              </span>
              <p className="bold-text">
                NOTE: Your payment indicates that job(s) is/are complete and
                that you accept quality of work as defined on the
                Proposal/Estimate/Invoice and have read and understand
                guarantee.
              </p>
            </li>
            <li>
              <span className="bold-text">
                Until the labor is completed to your satisfaction, PLEASE DO NOT
                PAY!
              </span>
            </li>
            <li>
              <span className="bold-text">
                Handyman can purchase materials needed for the job or you may
                furnish; 3% Handling Fee on materials purchased by Handyman.
              </span>
            </li>
          </ul>
          <p>
            <span className="bold-text">GUARANTEE –</span> Payment is only made
            when you are completely satisfied with the finished job, providing
            peace of mind and a successful project. All labor has a one-year
            guarantee. If not completely satisfied, we make it right.
          </p>
          <p>
            This contract is between Handyman and customer for the work
            performed and between customer and SEND ME A PRO® for this
            Guarantee. This contract supersedes all other advertisements,
            previous agreement, or contract, written or oral. SEND ME A PRO®,
            referral service, provides the guarantee for one (1) year from date
            of this service, SEND ME A PRO® will advise this Handyman or another
            to repair, free of charge, defects in workmanship for work performed
            under this contract. Guarantee is for labor only, and does not apply
            to material failure, regular wear and tear, “acts of God” or a
            client’s change of mind regarding approval. Neither contract nor
            Guarantee is transferable. Guarantee become effective when your
            complete payment has been received by SEND ME A PRO®.
          </p>
          <p>
            Customer may supply all materials or may ask SEND ME A PRO® to
            purchase and transport materials. Customer agrees to reimburse SEND
            ME A PRO® for any materials purchased or supplied for use on this
            work. Cost of materials is in addition to labor cost indicated on
            contract.
          </p>
        </div>
      </>
    );
  };
  console.log("This is percent", percentage);
  return (
    <div className="container">
      <div>
        {invoiceContent()}
        <Button
          className="background-primary border-color-primary font-family-poppins text-light w-100 rounded-3"
          onClick={() => handleDownloadPDF()}
        >
          Accept
        </Button>
        {isLoading && (
          <div className="overlay">
            <Spinner animation="grow" variant="info" />
          </div>
        )}
      </div>
    </div>
  );
};

export { InvoiceHandyman };
