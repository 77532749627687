import { axiosInstance } from "../axios";

const getInvoiceDataApi = async (
  invoice_id: any,
  successCallback: (data: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.get(`/invoice/get-by-id/${invoice_id}`);
    successCallback(response);
  } catch (error) {
    errorCallback(error);
  }
};

const submitAfterSignedApi = async (
  data: any,
  successCallback: (data: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post(
      `/invoice/update-proposal-after-signed`,
      data
    );
    successCallback(response);
  } catch (error) {
    errorCallback(error);
  }
};

export { getInvoiceDataApi, submitAfterSignedApi };
