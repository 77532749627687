import { AppDispatch } from "../store";
import { getInvoiceDataApi, submitAfterSignedApi } from "../apis";

const getInvoiceDataAction = (
  invoice_id: any,
  successCallback?: (response: any) => void,
  errorCallback?: (error: any) => void
) => {
  return (dispatch: AppDispatch) => {
    getInvoiceDataApi(
      invoice_id,
      (response) => {
        if (response) {
          console.log("This is response", response);
          const { result } = response.data;
          if (successCallback) {
            successCallback(result);
          }
        }
      },
      (error) => {
        if (errorCallback) {
          console.error(error);
          errorCallback(error);
        }
      }
    );
  };
};

const submitAfterSignedAction = (
  data: any,
  successCallback?: (response: any) => void,
  errorCallback?: (error: any) => void
) => {
  return (dispatch: AppDispatch) => {
    submitAfterSignedApi(
      data,
      (response) => {
        if (response) {
          console.log("This is response", response);
          const { result } = response.data;
          if (successCallback) {
            successCallback(result);
          }
        }
      },
      (error) => {
        if (errorCallback) {
          console.error(error);
          errorCallback(error);
        }
      }
    );
  };
};

export { getInvoiceDataAction, submitAfterSignedAction };
